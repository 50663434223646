import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  ExternalHTMLContent,
  ImageMimeTypes,
  Section,
  SignatureField,
  TextGroup,
} from '@ac/kiosk-components';

import {
  Body,
  ComplementaryDetailsSection,
  Footer,
  Header,
  PersonalDetailsSection,
  ReservationDetailsSection,
  View,
} from 'components';
import { paths } from 'configs/paths';
import { uploadSignedCard } from 'store/electronicRegistrationProcess/actions';
import {
  getIsRegistrationCardCancelling,
  getRegistrationCardDetails,
} from 'store/electronicRegistrationProcess/selectors';
import {
  getConsentsEntities,
  getCustomMessages,
  getGeneralSettings,
} from 'store/settings/selectors';
import { useRouter, useScrollPositionRestoration } from 'utils/hooks';
import { mapConsentsData } from 'utils/regCardPresentationDataMappers';

import { PurposeOfStay } from './components/PurposeOfStay/PurposeOfStay';
import { TermsAndConditionsList } from './components/TermsAndConditionList/TermsAndConditionList';
import { ConsentConfirmationOption } from './types';

import './RegistrationCardConfirmation.scss';

const BODY_ID = 'RegistrationCardConfirmationBodyId';

export const RegistrationCardConfirmation = (): JSX.Element => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [signature, setSignature] = useState<Blob>();

  const {
    restoreScrollPosition,
    saveScrollPosition,
  } = useScrollPositionRestoration(`#${BODY_ID}`);

  const isRegistrationCardCancelling = useSelector(
    getIsRegistrationCardCancelling
  );
  const regCardDetails = useSelector(getRegistrationCardDetails);
  const generalSettings = useSelector(getGeneralSettings);
  const customMessages = useSelector(getCustomMessages);
  const consents = useSelector(getConsentsEntities);

  const isComplementaryDetailsSectionVisible = Boolean(
    generalSettings?.RESERVATION_HEADER_CUSTOM_FIELDS?.length
  );

  const termAndConditionOptions = useMemo((): ConsentConfirmationOption[] => {
    return mapConsentsData(regCardDetails?.profile.consents || [], consents);
  }, [regCardDetails, consents]);

  const handleDisclaimerLinkClick = useCallback(
    (link: string) => {
      saveScrollPosition();
      router.goTo(paths.EXTERNAL_LINK, {
        params: { link: encodeURIComponent(link) },
      });
    },
    [router, saveScrollPosition]
  );

  const handleConfirmClick = useCallback(() => {
    if (signature) {
      dispatch(uploadSignedCard.trigger(signature));
    }
  }, [dispatch, signature]);

  const handleSignatureChange = useCallback((signatureImage?: Blob) => {
    setSignature(signatureImage);
  }, []);

  useEffect(() => {
    restoreScrollPosition(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View>
      <Header title={t('REGISTRATION_CARD.TITLE')} />
      <Body id={BODY_ID} hasBackgroundImage={false}>
        {regCardDetails && (
          <>
            <ReservationDetailsSection
              reservationData={regCardDetails.reservation}
            />
            <PersonalDetailsSection
              personalData={regCardDetails.profile}
              showOnlyPrimary
            />
          </>
        )}

        {isComplementaryDetailsSectionVisible && (
          <ComplementaryDetailsSection
            complementaryDetails={regCardDetails?.reservation.reservationHeader}
          />
        )}

        <Section>
          <div className="reg-card-summary-section">
            <TermsAndConditionsList
              className="reg-card-summary-section-item"
              options={termAndConditionOptions}
            />
            {generalSettings?.DISPLAY_PURPOSE_OF_STAY && (
              <PurposeOfStay
                className="reg-card-summary-section-item"
                purposeOfStay={regCardDetails?.reservation.purposeOfStay}
              />
            )}
          </div>
          {customMessages?.DISCLAIMER && (
            <div className="spacing-top-xlg spacing-bottom-xlg">
              <ExternalHTMLContent
                onLinkClick={handleDisclaimerLinkClick}
                content={customMessages.DISCLAIMER}
              />
            </div>
          )}

          {regCardDetails?.confirmationNumber && (
            <div className="spacing-top-xxlg">
              <TextGroup
                label={t('REGISTRATION_CARD.REGISTRATION_CARD_NUMBER')}
                value={regCardDetails.confirmationNumber}
              />
            </div>
          )}

          <div className="reg-card-summary-signature spacing-top-lg">
            <SignatureField
              signatureImageMimeType={ImageMimeTypes.JPEG}
              label={t('REGISTRATION_CARD.SIGNATURE.SIGN_TO_CONFIRM')}
              placeholder={t('REGISTRATION_CARD.SIGNATURE.PLACEHOLDER')}
              onSignatureCompleted={handleSignatureChange}
              onReset={handleSignatureChange}
            />
          </div>
        </Section>
      </Body>

      <Footer
        hasPrimaryButton
        hasSecondaryButton
        isPrimaryDisabled={!signature}
        primaryLabel={t('SHARED.CONFIRM')}
        onSecondaryClick={router.goBack}
        onPrimaryClick={handleConfirmClick}
        hasCancelButton
        isRegistrationCardCancelling={isRegistrationCardCancelling}
      />
    </View>
  );
};
