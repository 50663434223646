import { createAction, getOneAction } from '@ac/library-utils/dist/redux-utils';

import { PrepareSetupPayload } from 'store/settings/interfaces/prepareSetupPayload';

import { Settings } from './interfaces';

export const prepareSetup = getOneAction<PrepareSetupPayload, undefined>(
  '@settings/prepareSetup'
);

export const applySetup = createAction<Settings>('@settings/applySetup');

export const updateBusinessDate = createAction<string>(
  '@settings/updateBusinessDate'
);

export const refetchConfiguration = {
  trigger: createAction('@settings/refetchConfigurationTrigger'),
  done: createAction('@settings/refetchConfigurationDone'),
};

export const refetchConfigurationJob = {
  start: createAction('@settings/job/startRefetchConfiguration'),
  stop: createAction('@settings/job/stopRefetchConfiguration'),
  reset: createAction('@settings/job/resetRefetchConfiguration'),
};

export const changeAppLanguage = getOneAction<string, undefined>(
  '@settings/changeAppLanguage'
);
