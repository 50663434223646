export enum FontFamilyCodesMap {
  ARVO = 'Arvo',
  CABIN = 'Cabin',
  CABINCONDENSED = 'Cabin Condensed',
  PTSANS = 'PT Sans',
  LORA = 'Lora',
  MERRIWEATHER = 'Merriweather',
  NOTOSANS = 'NotoSans',
  OPENSANSCONDENSED = 'Open Sans Condensed',
  PLAYFAIRDISPLAY = 'Playfair Display',
  RALEWAY = 'Raleway',
  ROBOTO = 'Roboto',
  ROBOTOMONO = 'Roboto Mono',
  OPENSANS = 'Open Sans',
  MONSERRAT = 'Montserrat',
  BOING = 'Montserrat',
}
