var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n  .purpose-of-stay-subtitle {\n    font-size: 10px;\n    margin: 0;\n  }\n\n  .purpose-of-stay-data {\n    font-size: 12px;\n    margin: 0;\n    margin-top: 4px;\n  }\n</style>\n\n<div>\n  <p class=\"purpose-of-stay-subtitle\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"subtitle") || (depth0 != null ? lookupProperty(depth0,"subtitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subtitle","hash":{},"data":data,"loc":{"start":{"line":15,"column":38},"end":{"line":15,"column":52}}}) : helper)))
    + "</p>\n  <p class=\"purpose-of-stay-data\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"data") || (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"data","hash":{},"data":data,"loc":{"start":{"line":16,"column":34},"end":{"line":16,"column":44}}}) : helper)))
    + "</p>\n</div>\n";
},"useData":true});