import { isDefined } from '@ac/library-utils/dist/utils';
import { ValidationExpression } from '@ac/react-infrastructure';

import {
  KioskReservation,
  KioskReservationHeaderDefinition,
  KioskReservationHeaderType,
} from 'api/KioskApi/entries';

import { BaseObject } from 'types/shared';

import {
  BasicFieldProvider,
  ComponentsData,
  FieldConfigOptions,
} from './fieldsDataProviders/basicFieldProvider';
import {
  BooleanFieldProvider,
  DateFieldProvider,
  DateTimeFieldProvider,
  EnumFieldProvider,
  IntegerFieldProvider,
  NumberFieldProvider,
  StringFieldProvider,
  TimeFieldProvider,
} from './fieldsDataProviders';

const typeToFieldMap = {
  [KioskReservationHeaderType.Boolean]: BooleanFieldProvider,
  [KioskReservationHeaderType.Decimal]: NumberFieldProvider,
  [KioskReservationHeaderType.Enum]: EnumFieldProvider,
  [KioskReservationHeaderType.Integer]: IntegerFieldProvider,
  [KioskReservationHeaderType.LocalDate]: DateFieldProvider,
  [KioskReservationHeaderType.LocalDateTime]: DateTimeFieldProvider,
  [KioskReservationHeaderType.LocalTime]: TimeFieldProvider,
  [KioskReservationHeaderType.Long]: NumberFieldProvider,
  [KioskReservationHeaderType.String]: StringFieldProvider,
};

export class CustomFieldsDataManager {
  public fieldControllers: BaseObject<BasicFieldProvider> = {};

  updateConfigurationData(
    config: FieldConfigOptions,
    reservationHeadersEnabledForRegCard: string[] = [],
    reservationHeaderDefinitions?: KioskReservationHeaderDefinition[],
    reservationHeaderValues?: KioskReservation['reservationHeader']
  ): void {
    const fieldControllersEntities = reservationHeadersEnabledForRegCard
      .map((item) => {
        const reservationHeaderDefinition = reservationHeaderDefinitions?.find(
          (definition) => definition.name === item
        );

        if (!reservationHeaderDefinition) return undefined;

        const FieldBuilderClass = reservationHeaderDefinition?.type
          ? typeToFieldMap[reservationHeaderDefinition?.type]
          : undefined;

        if (!FieldBuilderClass) return undefined;

        return [
          item,
          new FieldBuilderClass(
            reservationHeaderValues?.[item],
            reservationHeaderDefinition,
            config
          ),
        ];
      })
      .filter(isDefined);

    this.fieldControllers = Object.fromEntries(
      fieldControllersEntities
    ) as BaseObject<BasicFieldProvider>;
  }

  getInitialValues(): BaseObject<unknown> {
    return Object.fromEntries(
      Object.entries(this.fieldControllers).map(([key, controller]) => {
        return [key, controller.getInitialFieldValue()];
      })
    );
  }

  getRenderData(): BaseObject<ComponentsData> {
    return Object.fromEntries(
      Object.entries(this.fieldControllers).map(([key, controller]) => {
        return [key, controller.getComponentsData()];
      })
    );
  }

  getValidationSchema(): BaseObject<
    ValidationExpression<BaseObject<unknown>, unknown>
  > {
    return Object.fromEntries(
      Object.entries(this.fieldControllers)
        .map(([key, controller]) => {
          return [key, controller.getValidationSchema()];
        })
        .filter(([, validationExpressions]) => isDefined(validationExpressions))
    ) as BaseObject<ValidationExpression<BaseObject<unknown>, unknown>>;
  }

  prepareFormUpdatePayload(values: BaseObject<unknown>): BaseObject<unknown> {
    return Object.fromEntries(
      Object.entries(this.fieldControllers).map(([key, controller]) => {
        return [key, controller.prepareUpdatePayloadValue(values[key])];
      })
    );
  }
}
