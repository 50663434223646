import { PropsWithChildren, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Body as BasicBody } from '@ac/kiosk-components';

import { BackgroundImagePosition } from 'store/settings/interfaces';
import { getImages, getStyles } from 'store/settings/selectors';

import { GetComponentProps } from 'types/shared';

import './Body.scss';

interface BodyProps extends GetComponentProps<typeof BasicBody> {
  hasBackgroundImage?: boolean;
}

export const Body = ({
  children,
  dataTestSelector,
  hasBackgroundImage = true,
  ...props
}: PropsWithChildren<BodyProps>): JSX.Element => {
  const styles = useSelector(getStyles);
  const images = useSelector(getImages);

  const backgroundImageStyles = useMemo(() => {
    if (!hasBackgroundImage || !images?.BACKGROUND_IMAGE) return;

    return {
      backgroundImage: `url(${images?.BACKGROUND_IMAGE})`,
    };
  }, [images?.BACKGROUND_IMAGE, hasBackgroundImage]);

  const imagePositionClass = useMemo(() => {
    if (!hasBackgroundImage || !images?.BACKGROUND_IMAGE) return;
    const imagePosition =
      styles?.BACKGROUND_IMAGE_POSITION || BackgroundImagePosition.Tile;

    return `body-wrapper-image-position-${imagePosition.toLocaleLowerCase()}`;
  }, [
    styles?.BACKGROUND_IMAGE_POSITION,
    images?.BACKGROUND_IMAGE,
    hasBackgroundImage,
  ]);

  return (
    <div
      data-test-selector={dataTestSelector}
      style={backgroundImageStyles}
      className={classNames('body-wrapper', imagePositionClass)}
    >
      <BasicBody {...props}>{children}</BasicBody>
    </div>
  );
};
