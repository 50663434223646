import { Component, PropsWithChildren } from 'react';

import {
  ErrorView,
  LogReporter,
  MagicButton,
  MagicButtonHorizontalPosition,
  MagicButtonVerticalPosition,
} from '@ac/kiosk-components';

import { EnvironmentSettingsModal } from 'components/index';
import { LOG_MESSAGES_TITLES, LOG_TYPES } from 'configs/logs';

import './ErrorBoundary.scss';

interface ErrorBoundaryState {
  isUncatchedException: boolean;
  isServiceModalOpen: boolean;
}

export class ErrorBoundary extends Component<
  PropsWithChildren<unknown>,
  ErrorBoundaryState
> {
  public state: ErrorBoundaryState = {
    isUncatchedException: false,
    isServiceModalOpen: false,
  };

  public componentDidMount(): void {
    window.addEventListener('unhandledrejection', this.reportReactError);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('unhandledrejection', this.reportReactError);
  }

  public componentDidCatch(error: Error): void {
    if (error) {
      this.setState({ isUncatchedException: true });
      this.reportReactError(error);
    }
  }

  private reportReactError = (error: Error | PromiseRejectionEvent): void => {
    const isPromiseRejection = typeof error === 'object' && 'reason' in error;

    LogReporter.log.fatal(
      LOG_TYPES.app,
      {
        message: isPromiseRejection
          ? LOG_MESSAGES_TITLES.reactUnhandledRefection
          : LOG_MESSAGES_TITLES.reactCriticalError,
        details: {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          message: isPromiseRejection
            ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              (error as PromiseRejectionEvent).reason?.message
            : (error as Error)?.message,
          location: window.location.pathname,
        },
      },
      true
    );
  };

  private toggleServiceModal = (): void => {
    const { isServiceModalOpen } = this.state;
    this.setState({ isServiceModalOpen: !isServiceModalOpen });
  };

  public render(): React.ReactNode {
    const { isUncatchedException, isServiceModalOpen } = this.state;

    return isUncatchedException ? (
      <>
        <MagicButton
          dataTestSelector="error-modal-magic-button"
          positionHorizontal={MagicButtonHorizontalPosition.left}
          positionVertical={MagicButtonVerticalPosition.top}
          width={90}
          height={90}
          pressTimeout={5000}
          onLongPress={this.toggleServiceModal}
          className="error-modal-magic-button"
        />

        {isServiceModalOpen && (
          <EnvironmentSettingsModal onCancelClick={this.toggleServiceModal} />
        )}

        <ErrorView />
      </>
    ) : (
      this.props.children
    );
  }
}
