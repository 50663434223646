export enum FormProperties {
  purposeOfStay = 'purposeOfStay',
  etd = 'etd',
  termsAndCondition = 'termsAndCondition',
}

export interface FormValues {
  [FormProperties.purposeOfStay]: string;
  [FormProperties.etd]: string;
  [FormProperties.termsAndCondition]: {
    [key: string]: boolean;
  };
}
