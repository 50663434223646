import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  FormHeader,
  Section,
  SectionType,
  TextSize,
  TextWeight,
  ValidationHeader,
} from '@ac/kiosk-components';
import {
  FieldRenderProps,
  Form,
  FormApi,
  FormValidationErrors,
} from '@ac/react-infrastructure';

import {
  Body,
  Footer,
  Header,
  ReservationDetailsSection,
  View,
} from 'components';
import { getRegistrationCardDetails } from 'store/electronicRegistrationProcess/selectors';
import {
  getDateTimeFormats,
  getGeneralSettings,
  getReservationHeaderDefinition,
} from 'store/settings/selectors';
import { getErrorMessage, smoothScrollInToCenter } from 'utils';
import { FormValidator } from 'utils/form';
import { useRouter } from 'utils/hooks';

import { BaseObject } from 'types/shared';

import {
  clearRegistrationCardEditComplementary,
  updateComplementaryDetails,
} from './store/actions';
import {
  getUpdateComplementaryDetailsErrors,
  getUpdateComplementaryDetailsState,
} from './store/selectors';
import { CustomFieldsDataManager } from './utils/customFieldsDataManager';
import { ComponentsData } from './utils/fieldsDataProviders/basicFieldProvider';
import { BODY_ID } from './config';

import './RegistrationCardEditComplementary.scss';

interface RegistrationCardEditComplementaryState {
  initialValues: BaseObject<unknown>;
  validator:
    | FormValidator<
        BaseObject<unknown>,
        FormValidationErrors<BaseObject<unknown>>
      >
    | undefined;
  fieldsRenderData: BaseObject<ComponentsData> | undefined;
}

export const RegistrationCardEditComplementary = (): JSX.Element => {
  const customFieldsDataManager = useRef(new CustomFieldsDataManager());

  const router = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [
    editComplementaryState,
    setEditComplementaryState,
  ] = useState<RegistrationCardEditComplementaryState>({
    initialValues: {},
    validator: undefined,
    fieldsRenderData: undefined,
  });

  const regCardDetails = useSelector(getRegistrationCardDetails);
  const dateTimeFormats = useSelector(getDateTimeFormats);
  const generalSettings = useSelector(getGeneralSettings);
  const errors = useSelector(getUpdateComplementaryDetailsErrors);
  const reservationHeaderDefinitions = useSelector(
    getReservationHeaderDefinition
  );
  const isComplementaryDetailsUpdating = useSelector(
    getUpdateComplementaryDetailsState
  );

  const scrollToFirstInvalidField = (): void => {
    const invalidFieldToScroll = editComplementaryState.validator?.getInvalidFieldsPaths()?.[0] as
      | string
      | undefined;
    if (!invalidFieldToScroll) return;
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    smoothScrollInToCenter(`[name="${invalidFieldToScroll}"]`, `#${BODY_ID}`);
  };

  const handleBack = useCallback(() => {
    router.goBack();
    dispatch(clearRegistrationCardEditComplementary());
  }, [router, dispatch]);

  const onFormSubmit = useCallback(
    (values: BaseObject<unknown>, formApi: FormApi<BaseObject<unknown>>) => {
      if (!formApi.getState()?.dirty) return handleBack();
      const valuesToUpdate = customFieldsDataManager.current.prepareFormUpdatePayload(
        values
      );

      const validValues =
        valuesToUpdate &&
        Object.values(valuesToUpdate).filter((value) => value !== undefined);

      if (!validValues?.length) return handleBack();

      dispatch(
        updateComplementaryDetails.trigger({
          data: valuesToUpdate,
          onSuccessfulUpdate: handleBack,
        })
      );
    },
    [dispatch, handleBack]
  );

  useEffect(() => {
    customFieldsDataManager.current.updateConfigurationData(
      {
        shortDateFormat: dateTimeFormats.shortDateFormat,
        timeFormat: dateTimeFormats.timeFormat,
      },
      generalSettings?.RESERVATION_HEADER_CUSTOM_FIELDS,
      reservationHeaderDefinitions,
      regCardDetails?.reservation.reservationHeader
    );

    setEditComplementaryState({
      initialValues: customFieldsDataManager.current.getInitialValues(),
      validator: new FormValidator<
        BaseObject<unknown>,
        FormValidationErrors<BaseObject<unknown>>
      >(customFieldsDataManager.current.getValidationSchema()),
      fieldsRenderData: customFieldsDataManager.current.getRenderData(),
    });
  }, [
    dateTimeFormats,
    generalSettings,
    regCardDetails,
    reservationHeaderDefinitions,
  ]);

  return (
    <View
      isLoading={isComplementaryDetailsUpdating}
      isError={Boolean(errors.length)}
      errorMessage={getErrorMessage(errors, t)}
      onErrorModalSubmit={handleBack}
    >
      <Header title={t('SHARED.REGISTRATION')} />

      <Form
        initialValues={editComplementaryState.initialValues}
        onSubmit={onFormSubmit}
        validate={editComplementaryState.validator?.validate}
        subscription={{ valid: true }}
      >
        {(formRenderProps): JSX.Element => (
          <>
            <Body
              className="registration-card-edit-complementary-wrapper"
              id={BODY_ID}
            >
              {regCardDetails && (
                <ReservationDetailsSection
                  reservationData={regCardDetails.reservation}
                />
              )}

              <Section type={SectionType.wide}>
                <FormHeader
                  title={t('SHARED.DEFAULT_FORM_TITLE')}
                  hint={t('SHARED.DEFAULT_FORM_HINT')}
                />

                <ValidationHeader
                  titleWeight={TextWeight.regular}
                  titleSize={TextSize.xlg}
                  className="spacing-top-xlg spacing-bottom-lg"
                  title={t('SHARED.COMPLEMENTARY_DETAILS')}
                  isValid={formRenderProps.valid}
                />

                <div className="registration-card-edit-complementary-fields-container">
                  {editComplementaryState.fieldsRenderData &&
                    Object.entries(editComplementaryState.fieldsRenderData).map(
                      ([
                        key,
                        {
                          FormField,
                          formFieldRenderPropsMapper,
                          Component,
                          componentProps,
                        },
                      ]) => {
                        return (
                          <FormField valuePath={key} key={key}>
                            {(
                              fieldRenderProps: FieldRenderProps<unknown>
                            ): JSX.Element => (
                              <Component
                                {...formFieldRenderPropsMapper(
                                  fieldRenderProps
                                )}
                                {...componentProps}
                                className="registration-card-edit-complementary-field"
                              />
                            )}
                          </FormField>
                        );
                      }
                    )}
                </div>
              </Section>
            </Body>

            <Footer
              hasPrimaryButton
              hasSecondaryButton
              hasCancelButton
              primaryLabel={t('SHARED.SAVE')}
              onSecondaryClick={handleBack}
              onPrimaryClick={(): void => {
                formRenderProps.handleSubmit();
                if (
                  editComplementaryState.validator &&
                  !editComplementaryState.validator.isValid
                ) {
                  scrollToFirstInvalidField();
                }
              }}
            />
          </>
        )}
      </Form>
    </View>
  );
};
