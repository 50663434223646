var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":32,"column":4},"end":{"line":38,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":33,"column":6},"end":{"line":37,"column":15}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/reservationDetailsSubsection.hbs"),depth0,{"name":"partials/reservationDetailsSubsection","hash":{"sectionData":((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"sectionData") : stack1)},"data":data,"blockParams":blockParams,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/guestDetailsSubsection.hbs"),depth0,{"name":"partials/guestDetailsSubsection","hash":{"data":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"personal") : stack1)) != null ? lookupProperty(stack1,"data") : stack1),"subsectionTitle":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"personal") : stack1)) != null ? lookupProperty(stack1,"title") : stack1),"class":"guest-details-standard-subsection"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"address") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":4},"end":{"line":55,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"contact") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":4},"end":{"line":63,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/guestDetailsSubsection.hbs"),depth0,{"name":"partials/guestDetailsSubsection","hash":{"data":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"idAndDocuments") : stack1)) != null ? lookupProperty(stack1,"data") : stack1),"noDataInfo":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"idAndDocuments") : stack1)) != null ? lookupProperty(stack1,"noDataInfo") : stack1),"subsectionTitle":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"idAndDocuments") : stack1)) != null ? lookupProperty(stack1,"title") : stack1),"withSeparators":"true","class":"guest-details-wrappable-subsection"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/guestDetailsSubsection.hbs"),depth0,{"name":"partials/guestDetailsSubsection","hash":{"data":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"address") : stack1)) != null ? lookupProperty(stack1,"data") : stack1),"noDataInfo":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"address") : stack1)) != null ? lookupProperty(stack1,"noDataInfo") : stack1),"subsectionTitle":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"address") : stack1)) != null ? lookupProperty(stack1,"title") : stack1),"withSeparators":"true","class":"guest-details-address-section"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/guestDetailsSubsection.hbs"),depth0,{"name":"partials/guestDetailsSubsection","hash":{"data":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"contact") : stack1)) != null ? lookupProperty(stack1,"data") : stack1),"noDataInfo":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"contact") : stack1)) != null ? lookupProperty(stack1,"noDataInfo") : stack1),"subsectionTitle":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"contact") : stack1)) != null ? lookupProperty(stack1,"title") : stack1),"class":"guest-details-wrappable-subsection"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/section.hbs"),depth0,{"name":"partials/section","hash":{"sectionTitle":((stack1 = (depth0 != null ? lookupProperty(depth0,"complementaryDetailsSection") : depth0)) != null ? lookupProperty(stack1,"title") : stack1)},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/complementaryDetails.hbs"),depth0,{"name":"partials/complementaryDetails","hash":{"data":((stack1 = (depth0 != null ? lookupProperty(depth0,"complementaryDetailsSection") : depth0)) != null ? lookupProperty(stack1,"data") : stack1)},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/consentList.hbs"),depth0,{"name":"partials/consentList","hash":{"data":((stack1 = (depth0 != null ? lookupProperty(depth0,"consentSection") : depth0)) != null ? lookupProperty(stack1,"data") : stack1)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"additional-information-item\">\n"
    + ((stack1 = container.invokePartial(require("./partials/section.hbs"),depth0,{"name":"partials/section","hash":{"sectionTitle":((stack1 = (depth0 != null ? lookupProperty(depth0,"purposeOfStaySection") : depth0)) != null ? lookupProperty(stack1,"title") : stack1)},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/purposeOfStay.hbs"),depth0,{"name":"partials/purposeOfStay","hash":{"data":((stack1 = (depth0 != null ? lookupProperty(depth0,"purposeOfStaySection") : depth0)) != null ? lookupProperty(stack1,"data") : stack1),"subtitle":((stack1 = (depth0 != null ? lookupProperty(depth0,"purposeOfStaySection") : depth0)) != null ? lookupProperty(stack1,"subtitle") : stack1)},"data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/section.hbs"),depth0,{"name":"partials/section","fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/disclaimer.hbs"),depth0,{"name":"partials/disclaimer","hash":{"content":((stack1 = (depth0 != null ? lookupProperty(depth0,"disclaimerSection") : depth0)) != null ? lookupProperty(stack1,"content") : stack1)},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"registration-card-number\">\n"
    + ((stack1 = container.invokePartial(require("./partials/textGroup.hbs"),depth0,{"name":"partials/textGroup","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"registrationCardNumber") : depth0)) != null ? lookupProperty(stack1,"value") : stack1),"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"registrationCardNumber") : depth0)) != null ? lookupProperty(stack1,"title") : stack1)},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/signature.hbs"),depth0,{"name":"partials/signature","hash":{"signatureImage":((stack1 = (depth0 != null ? lookupProperty(depth0,"signatureSection") : depth0)) != null ? lookupProperty(stack1,"signature") : stack1),"date":((stack1 = (depth0 != null ? lookupProperty(depth0,"signatureSection") : depth0)) != null ? lookupProperty(stack1,"creationDateTime") : stack1),"title":((stack1 = (depth0 != null ? lookupProperty(depth0,"signatureSection") : depth0)) != null ? lookupProperty(stack1,"title") : stack1)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n  .additional-information-section {\n    display: flex;\n  }\n\n  .additional-information-item {\n    flex: 1;\n  }\n\n  .additional-information-item:not(:last-of-type) {\n    margin-right: 8px;\n  }\n\n  .guest-details-standard-subsection,\n  .guest-details-address-section {\n    grid-row: span 3;\n  }\n\n  .guest-details-address-section ~ .guest-details-wrappable-subsection {\n    grid-row: span 1 !important;\n  }\n\n  .registration-card-number {\n    margin-top: 24px;\n  }\n</style>\n\n<div>\n"
    + ((stack1 = container.invokePartial(require("./partials/header.hbs"),depth0,{"name":"partials/header","hash":{"logo":(depth0 != null ? lookupProperty(depth0,"logo") : depth0),"title":(depth0 != null ? lookupProperty(depth0,"title") : depth0)},"data":data,"blockParams":blockParams,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./partials/section.hbs"),depth0,{"name":"partials/section","hash":{"subtitle":((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationSection") : depth0)) != null ? lookupProperty(stack1,"subtitle") : stack1),"sectionTitle":((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationSection") : depth0)) != null ? lookupProperty(stack1,"title") : stack1)},"fn":container.program(1, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./partials/section.hbs"),depth0,{"name":"partials/section","hash":{"sectionTitle":((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"title") : stack1)},"fn":container.program(5, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"complementaryDetailsSection") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":73,"column":2},"end":{"line":77,"column":9}}})) != null ? stack1 : "")
    + "\n  <div class=\"additional-information-section\" data-avoid-break-page-inside=\"true\">\n    <div class=\"additional-information-item\">\n"
    + ((stack1 = container.invokePartial(require("./partials/section.hbs"),depth0,{"name":"partials/section","hash":{"sectionTitle":((stack1 = (depth0 != null ? lookupProperty(depth0,"consentSection") : depth0)) != null ? lookupProperty(stack1,"title") : stack1)},"fn":container.program(13, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"purposeOfStaySection") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":85,"column":4},"end":{"line":94,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"disclaimerSection") : depth0)) != null ? lookupProperty(stack1,"content") : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":97,"column":2},"end":{"line":101,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"registrationCardNumber") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":103,"column":2},"end":{"line":110,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./partials/section.hbs"),depth0,{"name":"partials/section","fn":container.program(23, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true,"useBlockParams":true});