import i18next from 'i18next';

import {
  ComplementaryPresentationDataItem,
  ConsentPresentationDataElement,
  ProfilePresentationDataElement,
  ReservationPresentationDataElement,
} from 'utils/regCardPresentationDataMappers';

import registerHandlebarsHelpers from './registerHandlebarsHelpers';
import hbs from './template.hbs';

registerHandlebarsHelpers();

export interface RegistrationCardPdfData {
  reservationSubsections: ReservationPresentationDataElement;
  personalSubsection: ProfilePresentationDataElement[];
  addressSection: ProfilePresentationDataElement[];
  contactSection: ProfilePresentationDataElement[];
  documentSubsenctions: ProfilePresentationDataElement[];
  complementaryDetails: ComplementaryPresentationDataItem[];
  consentsSection: ConsentPresentationDataElement[];
  purposeOfStaySection: string | undefined;
  disclaimer: string | undefined;
  creationDateTime: string;
  signature: string;
  registrationCardNumber?: string;
  logo: string | undefined;
}

export interface RegistrationCardPdfConfig {
  isAddressEnabled?: boolean;
  isContactEnabled?: boolean;
  isPurposeOfStayEnabled?: boolean;
  isComplementaryDetailsAvailable?: boolean;
}

export const createRegistrationCardPdfContent = (
  content: RegistrationCardPdfData,
  config: RegistrationCardPdfConfig
): string => {
  const templateContext = {
    title: i18next.t('PDF.REGISTRATION_CARD'),
    logo: content.logo,
    reservationSection: {
      title: i18next.t('COMPONENTS.RESERVATION_DETAILS_SECTION.TITLE'),
      subtitle: content.reservationSubsections.subtitle,
      subsections: content.reservationSubsections.sections,
    },
    guestSection: {
      title: i18next.t('PDF.GUEST_DETAILS'),
      subsections: {
        personal: {
          title: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.PERSONAL'),
          data: content.personalSubsection,
        },
        ...(config.isAddressEnabled && {
          address: {
            title: i18next.t('SHARED.ADDRESS'),
            noDataInfo: i18next.t('SHARED.NO_ADDRESS'),
            data: content.addressSection,
          },
        }),
        ...(config.isContactEnabled && {
          contact: {
            title: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.CONTACT'),
            noDataInfo: i18next.t('SHARED.NO_CONTACT'),
            data: content.contactSection,
          },
        }),

        idAndDocuments: {
          title: i18next.t(
            'COMPONENTS.PERSONAL_DETAILS_SECTION.ID_AND_DOCUMENTS'
          ),
          noDataInfo: i18next.t('SHARED.NO_DOCUMENT'),
          data: content.documentSubsenctions,
        },
      },
    },
    ...(config.isComplementaryDetailsAvailable &&
      content.complementaryDetails.length && {
        complementaryDetailsSection: {
          title: i18next.t('SHARED.COMPLEMENTARY_DETAILS'),
          data: content.complementaryDetails,
        },
      }),
    consentSection: {
      title: i18next.t('REGISTRATION_CARD.TERMS_AND_CONDITIONS.TITLE'),
      data: content.consentsSection,
    },
    ...(config.isPurposeOfStayEnabled && {
      purposeOfStaySection: {
        title: i18next.t('REGISTRATION_CARD.ADDITIONAL_DETAILS.TITLE'),
        subtitle: i18next.t('REGISTRATION_CARD.ADDITIONAL_DETAILS.HINT'),
        data: content.purposeOfStaySection,
      },
    }),
    disclaimerSection: {
      content: content.disclaimer,
    },
    ...(content.registrationCardNumber && {
      registrationCardNumber: {
        title: i18next.t('REGISTRATION_CARD.REGISTRATION_CARD_NUMBER'),
        value: content.registrationCardNumber,
      },
    }),
    signatureSection: {
      title: i18next.t('PDF.SIGNATURE'),
      signature: content.signature,
      creationDateTime: content.creationDateTime,
    },
  };

  return hbs(templateContext);
};
