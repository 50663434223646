import { createSelector } from 'reselect';

import { ApiError } from '@ac/library-api';

import { KioskRegCardDetails } from 'api/KioskApi/entries';
import { Store } from 'store/types';

import { BaseObject } from 'types/shared';

export const getElectronicRegistrationProcessFetchState = (
  state: Store
): boolean =>
  Object.values(state.electronicRegistrationProcess.fetching).includes(true);

export const getElectronicRegistrationProcessErrors = (
  state: Store
): Array<ApiError | Error> => state.electronicRegistrationProcess.errors;

export const getIsRegistrationCardReceived = (state: Store): boolean =>
  Boolean(state.electronicRegistrationProcess.registrationCardId);

export const getRegistrationCardId = (state: Store): string | undefined =>
  state.electronicRegistrationProcess.registrationCardId;

export const getElectronicRegistrationProcessId = (
  state: Store
): string | undefined => state.electronicRegistrationProcess.processId;

export const getRegistrationCardDetails = (
  state: Store
): KioskRegCardDetails | undefined =>
  state.electronicRegistrationProcess.regCardDetails;

export const getIsRegistrationCardCancelling = (state: Store): boolean =>
  !!state.electronicRegistrationProcess.fetching.regCardCancelling;

export const getIsRegistrationCardCancelled = (state: Store): boolean =>
  state.electronicRegistrationProcess.regCardCancelled;

export const getIsRegistrationCardCompleted = (state: Store): boolean =>
  state.electronicRegistrationProcess.regCardCompleted;

export const getIsAnotherSessionOpenedInNewTab = (state: Store): boolean =>
  state.electronicRegistrationProcess.regCardAnotherSessionOpenedInNewTab;

export const getCachedAppliedConsents = (
  state: Store
): BaseObject<boolean> | undefined =>
  state.electronicRegistrationProcess.cachedAppliedConsents;

export const getCachedPurposeOfStay = (state: Store): string | undefined =>
  state.electronicRegistrationProcess.cachedPurposeOfStay;

export const getCachedEtd = (state: Store): string | undefined =>
  state.electronicRegistrationProcess.cachedEtd;

export const getIsElectronicRegistrationProcessInitialized = createSelector(
  getRegistrationCardId,
  getElectronicRegistrationProcessId,
  getRegistrationCardDetails,
  (regCardId, processId, regCardDetails): boolean =>
    Boolean(regCardId && processId && regCardDetails)
);
