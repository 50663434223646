import { getListAction } from '@ac/library-utils/dist/redux-utils';

import { KioskDevice, KioskProperty } from 'api/KioskApi/entries';

export const fetchPropertyList = getListAction<undefined, KioskProperty[]>(
  '@login/fetchPropertyList'
);

export const fetchDeviceList = getListAction<undefined, KioskDevice[]>(
  '@login/fetchDeviceList'
);
