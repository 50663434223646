import {
  createRequiredValidator,
  ValidationResult,
} from '@ac/react-infrastructure';

import { BaseObject } from 'types/shared';

export const createConditionalRequire = (
  validationMessage: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  condition: (value: any, model: BaseObject, index?: number) => boolean
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
): ((value: any, model: BaseObject, index?: number) => ValidationResult) => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  model: BaseObject,
  index?: number
) => {
  const requireValidator = createRequiredValidator(validationMessage);

  return condition(value, model, index) ? requireValidator(value) : [];
};
