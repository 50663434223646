import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Button,
  ButtonPattern,
  ExpandPanel,
  IconTypes,
  Section,
  SectionType,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import { KioskReservation } from 'api/KioskApi/entries';
import {
  getDateTimeFormats,
  getGeneralSettings,
} from 'store/settings/selectors';
import { useMatchMediaQuery } from 'utils/hooks';
import { mapReservationData } from 'utils/regCardPresentationDataMappers';

import { ReservationSubSection } from './ReservationSubSection/ReservationSubSection';

import './ReservationDetailsSection.scss';

interface ReservationDetailsSectionProps {
  reservationData: KioskReservation;
}

export const ReservationDetailsSection = ({
  reservationData,
}: ReservationDetailsSectionProps): JSX.Element => {
  const [t, i18n] = useTranslation();
  const [isSectionExpanded, setSectionExpanded] = useState(false);
  const isLargeDevice = useMatchMediaQuery();
  const generalSettings = useSelector(getGeneralSettings);
  const dateTimeFormats = useSelector(getDateTimeFormats);

  const sectionToggle = useCallback(() => {
    return setSectionExpanded(!isSectionExpanded);
  }, [isSectionExpanded]);

  const displayDetails = useMemo(() => {
    return mapReservationData(reservationData, {
      longDateFormat: dateTimeFormats?.longDateFormat,
      timeFormat: dateTimeFormats?.timeFormat,
      isMembershipEnabled: generalSettings?.DISPLAY_MEMBERSHIP,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateTimeFormats?.longDateFormat,
    dateTimeFormats?.timeFormat,
    reservationData,
    i18n.language,
  ]);

  const subtitle = displayDetails.subtitle;

  return (
    <Section type={SectionType.wide}>
      <div className="reservation-details-wrapper">
        <div className="reservation-details-section">
          <ExpandPanel isExpanded={isLargeDevice || isSectionExpanded}>
            <Text size={TextSize.xlg}>
              {t('COMPONENTS.RESERVATION_DETAILS_SECTION.TITLE')}
            </Text>
            {subtitle && <Text className="spacing-top-sm">{subtitle}</Text>}
            <div className="reservation-details-content">
              {displayDetails.sections.map((data, index) => (
                <ReservationSubSection key={index} data={data} />
              ))}
            </div>
          </ExpandPanel>
        </div>
        {!isLargeDevice && (
          <Button
            icon={isSectionExpanded ? IconTypes.collapse : IconTypes.expand}
            pattern={ButtonPattern.secondary}
            onClick={sectionToggle}
          />
        )}
      </div>
    </Section>
  );
};
