import { put, takeLatest } from 'redux-saga/effects';

import { ElectronicRegistrationApi } from 'api/KioskApi';
import { KioskDevice, KioskProperty } from 'api/KioskApi/entries';

import { SagasGenerator } from 'types/shared';

import * as actions from './actions';

function* fetchPropertiesList(): SagasGenerator {
  try {
    const properties: KioskProperty[] = yield ElectronicRegistrationApi.getProperties();
    yield put(actions.fetchPropertyList.success(properties));
  } catch (e) {
    yield put(actions.fetchPropertyList.failure(e));
  }
}

function* fetchDevicesList(): SagasGenerator {
  try {
    const devices: KioskDevice[] = yield ElectronicRegistrationApi.getDevices();

    yield put(actions.fetchDeviceList.success(devices));
  } catch (e) {
    yield put(actions.fetchDeviceList.failure(e));
  }
}

export function* loginViewSagas(): SagasGenerator {
  yield takeLatest(actions.fetchPropertyList.trigger, fetchPropertiesList);
  yield takeLatest(actions.fetchDeviceList.trigger, fetchDevicesList);
}
