import { Action, ReducerMap } from 'redux-actions';

import { ApiError } from '@ac/library-api';
import { handleActions } from '@ac/library-utils/dist/redux-utils';
import { isDefined } from '@ac/library-utils/dist/utils';

import { KioskLoggedUser } from 'api/KioskApi/entries';
import { MissingPermissionsError } from 'utils/customErrors';

import { clearAllGlobalErrors } from '../globalActions';

import { PropertyPermissions } from './interfaces/state';
import * as actions from './actions';
import { AppState } from './interfaces';

const initialState: AppState = {
  isAppInitialized: false,
  isEnvironmentPrepared: false,
  isPollingRegistrationCardStarted: false,
  isSameDeviceScenario: false,
  currentPropertyId: undefined,
  currentDeviceId: undefined,
  currentUser: undefined,
  fetching: {
    prepareApplication: false,
    propertyData: false,
    prepareEnvironment: false,
    restartApplication: false,
  },
  errors: [],
  propertyPermissions: undefined,
};

const reducerMap: ReducerMap<AppState, unknown> = {
  [actions.prepareApplication.trigger]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        prepareApplication: true,
      },
    };
  },

  [actions.prepareApplication.success]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        prepareApplication: false,
      },
      isAppInitialized: true,
    };
  },

  [actions.prepareApplication.failure]: (
    state,
    action: Action<ApiError | Error | MissingPermissionsError>
  ) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        prepareApplication: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.prepareEnvironment.trigger]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        prepareEnvironment: true,
      },
    };
  },

  [actions.prepareEnvironment.success]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        prepareEnvironment: false,
      },
      isEnvironmentPrepared: true,
    };
  },

  [actions.prepareEnvironment.failure]: (
    state,
    action: Action<Error | null>
  ) => {
    const errors = [...state.errors, action.payload].filter(isDefined);

    return {
      ...state,
      fetching: {
        ...state.fetching,
        prepareEnvironment: false,
      },
      errors,
    };
  },

  [actions.selectProperty.trigger]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        propertyData: true,
      },
    };
  },

  [actions.selectProperty.success]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        propertyData: false,
      },
    };
  },

  [actions.selectProperty.failure]: (
    state,
    action: Action<ApiError | Error | MissingPermissionsError>
  ) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        propertyData: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.startPollingRegistrationCard]: (state) => {
    return {
      ...state,
      isPollingRegistrationCardStarted: true,
    };
  },

  [actions.stopPollingRegistrationCard]: (state) => {
    return {
      ...state,
      isPollingRegistrationCardStarted: false,
    };
  },

  [actions.setCurrentDevice]: (state, action: Action<string>) => {
    return {
      ...state,
      currentDeviceId: action.payload,
    };
  },

  [actions.setCurrentProperty]: (state, action: Action<string>) => {
    return {
      ...state,
      currentPropertyId: action.payload,
    };
  },

  [actions.setSameDeviceScenario]: (state) => {
    return {
      ...state,
      isSameDeviceScenario: true,
    };
  },

  [actions.setPropertyPermissions]: (
    state,
    action: Action<PropertyPermissions>
  ) => {
    return {
      ...state,
      propertyPermissions: action.payload,
    };
  },

  [actions.setCurrentUser]: (state, action: Action<KioskLoggedUser>) => {
    return {
      ...state,
      currentUser: action.payload,
    };
  },

  [actions.restartApplication]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        restartApplication: true,
      },
    };
  },

  [clearAllGlobalErrors]: (state) => {
    return {
      ...state,
      errors: [],
    };
  },
};

export const app = handleActions<AppState>(reducerMap, initialState);
