import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
  RadioButton,
  Text,
  TextSize,
  ValidationMessage,
} from '@ac/kiosk-components';
import {
  formRadioButtonFieldFactory,
  FormSpy,
  RadioButtonFieldRenderProps,
} from '@ac/react-infrastructure';

import { KioskDictionaryEntryDetails } from 'api/KioskApi/entries';
import {
  mapRadioFieldRenderProps,
  translateValidationResult,
} from 'utils/form';
import { sortDisplayElements } from 'utils/sorting';

import { FormProperties, FormValues } from '../../types';

import './PurposeOfStay.scss';

interface PurposeOfStayProps {
  id?: string;
  dataTestSelector?: string;
  className?: string;
  options: KioskDictionaryEntryDetails[];
}

const FormRadioField = formRadioButtonFieldFactory<
  Pick<FormValues, FormProperties.purposeOfStay>
>();

export const PurposeOfStay = ({
  id,
  dataTestSelector,
  className,
  options,
}: PurposeOfStayProps): JSX.Element => {
  const [t] = useTranslation();

  const stayOptions = useMemo(() => {
    return sortDisplayElements(options);
  }, [options]);

  return (
    <div
      id={id}
      className={classNames('reg-card-purpose-of-stay', className)}
      data-test-selector={dataTestSelector}
    >
      <Text size={TextSize.xs}>{`${t(
        'REGISTRATION_CARD.ADDITIONAL_DETAILS.HINT'
      )} *`}</Text>
      <div className="reg-card-purpose-of-stay-options">
        {stayOptions.map((option) => (
          <FormRadioField
            key={option.id}
            valuePath={FormProperties.purposeOfStay}
            value={option.id || ''}
          >
            {(
              fieldRenderProps: RadioButtonFieldRenderProps<string>
            ): JSX.Element => (
              <RadioButton
                {...mapRadioFieldRenderProps(fieldRenderProps)}
                className="reg-card-purpose-of-stay-options-item"
                label={option.description}
              />
            )}
          </FormRadioField>
        ))}
      </div>
      <FormSpy subscription={{ submitFailed: true, errors: true }}>
        {({ submitFailed, errors }): JSX.Element => (
          <ValidationMessage
            validationStatus={
              submitFailed
                ? translateValidationResult(
                    errors?.[FormProperties.purposeOfStay]
                  )
                : []
            }
          />
        )}
      </FormSpy>
    </div>
  );
};
