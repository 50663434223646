import { useTranslation } from 'react-i18next';

import {
  Badge,
  Button,
  ButtonPattern,
  IconTypes,
  Text,
  TextWeight,
} from '@ac/kiosk-components';

import './SubFormActionHeader.scss';

interface SubFormActionHeaderProps {
  title: string;
  isMain?: boolean;
  deleteControlEnabled?: boolean;
  mainControlEnabled?: boolean;
  setAsMainButtonDisabled?: boolean;
  onSetAsMainClick?: () => void;
  onRemoveClick?: () => void;
}

export const SubFormActionHeader = ({
  title,
  isMain,
  deleteControlEnabled,
  mainControlEnabled,
  setAsMainButtonDisabled,
  onSetAsMainClick,
  onRemoveClick,
}: SubFormActionHeaderProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="sub-form-action-header">
      <Text weight={TextWeight.bold}>{title}</Text>
      {mainControlEnabled && (
        <>
          {isMain ? (
            <Badge className="spacing-left-sm spacing-right-auto">
              {t(
                'REGISTRATION_CARD_EDIT_PERSONAL.SUB_FORM_ACTION_HEADER.MAIN_BADGE'
              )}
            </Badge>
          ) : (
            <Button
              pattern={ButtonPattern.tertiary}
              className="spacing-left-auto spacing-right-xxlg"
              onClick={onSetAsMainClick}
              disabled={setAsMainButtonDisabled}
            >
              {t(
                'REGISTRATION_CARD_EDIT_PERSONAL.SUB_FORM_ACTION_HEADER.SET_AS_MAIN'
              )}
            </Button>
          )}
        </>
      )}
      {deleteControlEnabled && (
        <Button
          pattern={ButtonPattern.tertiary}
          icon={IconTypes.trash}
          onClick={onRemoveClick}
        />
      )}
    </div>
  );
};
