import { getFactory, postFactory } from '@ac/library-api';

import {
  KioskRegCardDetails,
  KioskStartProcess,
  KioskUpdateConsents,
  KioskUpdateProfile,
  KioskUpdateReservationHeader,
  KioskUpdateStay,
  RawKioskRegCardDetails,
} from './entries';

const path = {
  start: '/kiosk/v0/electronic-registration/process/start',
  regCard: '/kiosk/v0/electronic-registration/process/registration-card',
  cancel: '/kiosk/v0/electronic-registration/process/cancel',
  updateStay: '/kiosk/v0/electronic-registration/process/update-stay',
  updateConsents: '/kiosk/v0/electronic-registration/process/update-consents',
  updateProfile: '/kiosk/v0/electronic-registration/process/update-profile',
  uploadSignedCard:
    '/kiosk/v0/electronic-registration/process/upload-signed-card',
  updateReservationHeader:
    '/kiosk/v0/electronic-registration/process/update-reservation-header',
};

export const ApiCacheKeyProcessRegCard = 'KioskApiCacheKeyProcessRegCard';

export const startElectronicRegistrationProcess = postFactory<
  undefined,
  KioskStartProcess
>(path.start);

export const getProcessRegCardDetails = getFactory<
  undefined,
  RawKioskRegCardDetails,
  KioskRegCardDetails
>({
  path: path.regCard,
  cacheKey: ApiCacheKeyProcessRegCard,
  ctor: KioskRegCardDetails,
});

export const cancelElectronicRegistrationProcess = postFactory(path.cancel);

export const updateStay = postFactory<undefined, KioskUpdateStay>(
  path.updateStay
);

export const updateConsents = postFactory<undefined, KioskUpdateConsents>(
  path.updateConsents
);

export const updateProfile = postFactory<undefined, KioskUpdateProfile>(
  path.updateProfile
);

export const uploadSignedCard = postFactory<undefined, FormData>(
  path.uploadSignedCard
);

export const updateReservationHeader = postFactory<
  undefined,
  KioskUpdateReservationHeader
>(path.updateReservationHeader);
