import { getListAction } from '@ac/library-utils/dist/redux-utils';

import { KioskStateDetails } from 'api/KioskApi/entries';

import { SuccessAction } from './interfaces/dictionaryDataStatus';

export const fetchCountryStates = getListAction<
  string,
  SuccessAction<KioskStateDetails[]>
>('@additionalDictionaries/countryStates');

export const fetchCountryDistricts = getListAction<
  string,
  SuccessAction<KioskStateDetails[]>
>('@additionalDictionaries/countryDistricts');
