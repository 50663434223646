import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { ConsoleSimulator } from '@ac/kiosk-components';

import { AppStateHandler, ErrorBoundary } from 'components';
import {
  APP_ROOT_CONTAINER_ID,
  CONSOLE_SIMULATOR_ROOT_CONTAINER_ID,
} from 'configs';
import { configureStore } from 'store/configureStore';
import { App } from 'views';

import { initializeApp } from './initializeApp';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './index.scss';

initializeApp();

const consoleSimulatorRoot = document.getElementById(
  CONSOLE_SIMULATOR_ROOT_CONTAINER_ID
);

if (consoleSimulatorRoot) {
  ReactDOM.createRoot(consoleSimulatorRoot).render(<ConsoleSimulator />);
}

const appContainerRoot = document.getElementById(APP_ROOT_CONTAINER_ID);

if (appContainerRoot) {
  ReactDOM.createRoot(appContainerRoot).render(
    <Provider store={configureStore()}>
      <Router>
        <ErrorBoundary>
          <AppStateHandler>
            <App />
          </AppStateHandler>
        </ErrorBoundary>
      </Router>
    </Provider>
  );
}

serviceWorkerRegistration.register();
