import { ReducerMap } from 'redux-actions';

import { ApiError } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

import { clearElectronicRegistrationProcess } from 'store/electronicRegistrationProcess/actions';

import * as actions from './actions';
import { RegistrationCardEditPersonalViewState } from './interfaces';

const initialState: RegistrationCardEditPersonalViewState = {
  fetching: {
    updateProfile: false,
  },
  errors: [],
};

const reducerMap: ReducerMap<RegistrationCardEditPersonalViewState, unknown> = {
  [actions.updateProfile.trigger]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        updateProfile: true,
      },
    };
  },

  [actions.updateProfile.success]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        updateProfile: false,
      },
    };
  },

  [actions.updateProfile.failure]: (
    state,
    action: Action<ApiError | Error>
  ) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        updateProfile: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.clearRegistrationCardEdit]: () => {
    return {
      ...initialState,
    };
  },

  [clearElectronicRegistrationProcess]: () => {
    return {
      ...initialState,
    };
  },
};

export const registrationCardEditPersonalView = handleActions<RegistrationCardEditPersonalViewState>(
  reducerMap,
  initialState
);
