export const paths = {
  LOGIN: '/login',
  SETUP: '/setup',
  EXTERNAL_DEVICE_ENTRY_PATH: '/',
  SAME_DEVICE_ENTRY_PATH: '/same-device/:propertyId/:cardId',
  CONSENT: 'consent/:consentId',
  EXTERNAL_LINK: 'external-link/:link',
  REGISTRATION_CARD_SUMMARY: 'registration-card-summary',
  REGISTRATION_CARD_EDIT_PERSONAL: 'registration-card-edit-personal',
  REGISTRATION_CARD_EDIT_COMPLEMENTARY_DETAILS:
    'registration-card-edit-complementary-details',
  REGISTRATION_CARD_CONFIRMATION: 'registration-card-confirmation',
  FINISH: 'finish',
};
