import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
  BasicModal,
  BasicModalSize,
  Button,
  ButtonPattern,
  Circular,
  CircularType,
  IconTypes,
  RadioButton,
  Text,
  TextSize,
} from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';
import {
  createRequiredValidator,
  Form,
  formRadioButtonFieldFactory,
} from '@ac/react-infrastructure';

import { FormValidator, mapRadioFieldRenderProps } from 'utils/form';

import { RadioButtonLabel } from './RadioButtonLabel/RadioButtonLabel';

import './DeleteConfirmationModal.scss';

interface DeleteConfirmationFormValues {
  replacementIdentifier?: number;
}

const FormRadioField = formRadioButtonFieldFactory<DeleteConfirmationFormValues>();

export interface ReplaceOption {
  identifier: number;
  title: string;
  description?: string;
}

interface DeleteConfirmationModalProps {
  header: string;
  description?: string;
  dataTestSelector?: string;
  className?: string;
  withReplacementOptions?: boolean;
  replaceOptions?: ReplaceOption[];
  onCancelClick?: () => void;
  onConfirmClick?: (identifier?: number) => void;
}

export const DeleteConfirmationModal = ({
  withReplacementOptions,
  header,
  description,
  dataTestSelector,
  className,
  replaceOptions,
  onCancelClick,
  onConfirmClick,
}: DeleteConfirmationModalProps): JSX.Element => {
  const { t } = useTranslation();

  const validator = useMemo(() => {
    if (!withReplacementOptions) return;

    return new FormValidator<DeleteConfirmationFormValues>({
      replacementIdentifier: createRequiredValidator('ACTION_REQUIRED'),
    });
  }, [withReplacementOptions]);

  const handleFormSubmit = useCallback(
    (formValues: DeleteConfirmationFormValues) => {
      if (isDefined(formValues.replacementIdentifier)) {
        return onConfirmClick?.(formValues.replacementIdentifier);
      }
      onConfirmClick?.();
    },
    [onConfirmClick]
  );

  return (
    <BasicModal
      size={withReplacementOptions ? BasicModalSize.lg : BasicModalSize.md}
      dataTestSelector={dataTestSelector}
      className={classNames('confirm-modal-wrapper', className)}
    >
      <div className="confirm-modal">
        <Circular
          className="confirm-modal-circular spacing-left-auto spacing-right-auto spacing-top-sm"
          type={CircularType.warning}
          icon={IconTypes.exclamationMark}
          iconClassName="confirm-modal-circular-icon"
        />
        <Text className="spacing-bottom-lg spacing-top-lg" size={TextSize.xlg}>
          {header}
        </Text>
        <Text>{description}</Text>

        <Form
          validate={validator?.validate}
          initialValues={{ replacementIdentifier: undefined }}
          onSubmit={handleFormSubmit}
        >
          {(formRenderProps): JSX.Element => (
            <>
              {withReplacementOptions && replaceOptions && (
                <div className="spacing-top-md replace-options-list">
                  {replaceOptions.map((option) => (
                    <FormRadioField
                      key={option.identifier}
                      valuePath="replacementIdentifier"
                      value={option.identifier}
                    >
                      {(fieldRenderProps): JSX.Element => (
                        <RadioButton
                          {...mapRadioFieldRenderProps(fieldRenderProps)}
                          label={
                            <RadioButtonLabel
                              title={option.title}
                              description={option.description}
                            />
                          }
                        />
                      )}
                    </FormRadioField>
                  ))}
                </div>
              )}

              <div className="confirm-modal-button-container spacing-top-lg">
                <Button
                  onClick={onCancelClick}
                  pattern={ButtonPattern.secondary}
                  className="spacing-right-xs"
                >
                  {t('SHARED.CANCEL')}
                </Button>
                <Button
                  onClick={formRenderProps.handleSubmit}
                  className="spacing-left-xs"
                  disabled={formRenderProps.invalid}
                >
                  {withReplacementOptions
                    ? t('SHARED.SAVE')
                    : t('SHARED.DELETE')}
                </Button>
              </div>
            </>
          )}
        </Form>
      </div>
    </BasicModal>
  );
};
