export class MissingPermissionsError extends Error {
  public title: string;
  public name: string;

  constructor(title: string, message: string) {
    super(message);

    this.title = title;
    this.name = 'MissingPermissionsError';
  }
}
