import { FontFamilyCodesMap } from '../interfaces/fontFamilyCodes';

export const getFontFamily = (
  fontCode?: string | keyof typeof FontFamilyCodesMap
): string => {
  const fontFamilyName =
    fontCode && FontFamilyCodesMap[fontCode as keyof typeof FontFamilyCodesMap];

  return [
    `"${fontFamilyName || FontFamilyCodesMap.OPENSANS}"`,
    'Roboto',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'sans-serif',
    'Arial',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(',');
};
