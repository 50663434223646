export enum GeneralSettingsCodesMap {
  SWITCH = 'EREGCARD',
  EDIT_MODE = 'EREGCARDEDITMODE',
  DISPLAY_PURPOSE_OF_STAY = 'EREGCARDDISPLAYPURPOSEOFSTAY',
  DEFAULT_EMAIL_TYPE_ID = 'EREGCARDDEFAULTEMAILTYPE',
  DEFAULT_MOBILE_TYPE_ID = 'EREGCARDDEFAULTMOBILETYPE',
  DEFAULT_PHONE_TYPE_ID = 'EREGCARDDEFAULTPHONETYPE',
  DEFAULT_ADDRESS_TYPE_ID = 'EREGCARDDEFAULTADDRESSTYPE',
  DISPLAY_MEMBERSHIP = 'EREGCARDDISPLAYMEMBERSHIP',
  MIDDLENAME = 'MIDDLENAME',
  SUFFIX = 'SUFFIX',
  SUGGESTED_PHONE_PREFIXES = 'SUGGESTEDPHONEPREFIXES',
  DISPLAY_ADDRESS = 'EREGCARDDISPLAYADDRESS',
  DISPLAY_MOBILE = 'EREGCARDDISPLAYMOBILE',
  DISPLAY_PHONE = 'EREGCARDDISPLAYPHONE',
  DISPLAY_EMAIL = 'EREGCARDDISPLAYEMAIL',
  SHORT_DATE_FORMAT = 'SHORTDATEFORMAT',
  LONG_DATE_FORMAT = 'LONGDATEFORMAT',
  RESERVATION_HEADER_CUSTOM_FIELDS = 'EREGRESERVATIONHEADERCUSTOMFIELDS',
  POP_COUNTRY_LIST = 'POPCOUNTRYLIST',
  DISTRICT = 'DISTRICT',
  LANGUAGE_SETTINGS = 'LANGEREG',
}

export interface LanguageRegCard {
  languageCode: string;
  supportedLanguages: string[];
}

export interface GeneralSettingsStorage {
  SWITCH?: boolean;
  EDIT_MODE?: boolean;
  DISPLAY_PURPOSE_OF_STAY?: boolean;
  DEFAULT_EMAIL_TYPE_ID?: string;
  DEFAULT_MOBILE_TYPE_ID?: string;
  DEFAULT_PHONE_TYPE_ID?: string;
  DEFAULT_ADDRESS_TYPE_ID?: string;
  DISPLAY_MEMBERSHIP?: boolean;
  MIDDLENAME?: boolean;
  SUFFIX?: boolean;
  SUGGESTED_PHONE_PREFIXES?: string[];
  DISPLAY_ADDRESS?: boolean;
  DISPLAY_MOBILE?: boolean;
  DISPLAY_PHONE?: boolean;
  DISPLAY_EMAIL?: boolean;
  SHORT_DATE_FORMAT?: string;
  LONG_DATE_FORMAT?: string;
  RESERVATION_HEADER_CUSTOM_FIELDS?: string[];
  POP_COUNTRY_LIST?: string[];
  DISTRICT?: boolean;
  LANGUAGE_SETTINGS?: LanguageRegCard;
}
