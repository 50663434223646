import { isDefined } from '@ac/library-utils/dist/utils';

import {
  KioskReservation,
  KioskReservationHeaderDefinition,
  KioskReservationHeaderType,
} from 'api/KioskApi/entries';

import { ComplementaryPresentationDataItem } from '../types';

import { basicDataMap } from './dataMappers/basicDataMap';
import { enumMap } from './dataMappers/enumMap';
import { localDateMap } from './dataMappers/localDateMap';
import { localDateTimeMap } from './dataMappers/localDateTimeMap';
import { localTimeMap } from './dataMappers/localTimeMap';
import { MapComplementaryDataOptions } from './types';

const complementaryDataTypeMap = {
  [KioskReservationHeaderType.Boolean]: basicDataMap,
  [KioskReservationHeaderType.Decimal]: basicDataMap,
  [KioskReservationHeaderType.Enum]: enumMap,
  [KioskReservationHeaderType.Integer]: basicDataMap,
  [KioskReservationHeaderType.LocalDate]: localDateMap,
  [KioskReservationHeaderType.LocalDateTime]: localDateTimeMap,
  [KioskReservationHeaderType.LocalTime]: localTimeMap,
  [KioskReservationHeaderType.Long]: basicDataMap,
  [KioskReservationHeaderType.String]: basicDataMap,
};

export const mapComplementaryData = (
  data?: KioskReservation['reservationHeader'],
  reservationHeaderDefinition?: KioskReservationHeaderDefinition[],
  options?: MapComplementaryDataOptions
): ComplementaryPresentationDataItem[] => {
  if (!options?.enabledCustomFields?.length || !reservationHeaderDefinition) {
    return [];
  }

  return (options?.enabledCustomFields || [])
    .map((key) => {
      const value = data?.[key];
      const headerDefinition = reservationHeaderDefinition.find(
        (definition) => definition.name === key
      );

      if (!headerDefinition || !headerDefinition.displayName) return undefined;

      const dataMapper = headerDefinition.type
        ? complementaryDataTypeMap[headerDefinition.type] || basicDataMap
        : basicDataMap;

      return {
        label: headerDefinition.displayName,
        value: dataMapper(
          value,
          options,
          headerDefinition.typeConstraints?.itemDefinitions
        ),
      };
    })
    .filter(isDefined);
};
