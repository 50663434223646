import i18next from 'i18next';
import { createSelector } from 'reselect';

import {
  ApiError,
  mapLongDateFormat,
  mapShortDateFormat,
} from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';

import {
  KioskConfigurationProperty,
  KioskReservationHeaderDefinition,
  KioskSupportedUiLanguages,
} from 'api/KioskApi/entries';
import { COMMUNICATION_MODES, DEFAULT_CONTACT_MODE_ORDER } from 'configs';
import { CHINESE_HARDCODED_DATE_FORMAT } from 'configs/constants';
import { Languages } from 'i18n';
import { Store } from 'store/types';
import { sortDisplayElements } from 'utils/sorting';

import {
  CustomMessagesSettingsStorage,
  EntitiesSettingsStorage,
  FeatureTogglesStorage,
  GeneralSettingsStorage,
  ImagesSettingsStorage,
  StylesSettingsStorage,
} from './interfaces';

export const getAreSettingsInitialized = (state: Store): boolean =>
  state.settings.areSettingsInitialized;

export const getSettingsErrors = (state: Store): Array<ApiError | Error> =>
  state.settings.errors;

export const getSettingsFetchState = (state: Store): boolean =>
  Object.values(state.settings.fetching).includes(true);

export const getRefetchingConfigurationFetchState = (state: Store): boolean =>
  state.settings.fetching.refetchingConfiguration;

export const getStyles = (state: Store): StylesSettingsStorage | undefined =>
  state.settings.styles;

export const getImages = (state: Store): ImagesSettingsStorage | undefined =>
  state.settings.images;

export const getSupportedUiLanguages = (
  state: Store
): KioskSupportedUiLanguages[] | undefined =>
  state.settings.entities?.supportedUiLanguages;

export const getCustomMessages = (
  state: Store
): CustomMessagesSettingsStorage | undefined => state.settings.customMessages;

export const getReservationHeaderDefinition = (
  state: Store
): KioskReservationHeaderDefinition[] | undefined =>
  state.settings.reservationHeaderDefinition;

export const getGeneralSettings = (
  state: Store
): GeneralSettingsStorage | undefined => state.settings.general;

export const getFeatureToggles = (
  state: Store
): FeatureTogglesStorage | undefined => state.settings.featureToggles;

export const getPropertyConfiguration = (
  state: Store
): KioskConfigurationProperty | undefined => state.settings.property;

export const getEntities = (
  state: Store
): EntitiesSettingsStorage | undefined => state.settings.entities;

export const getCountryEntities = createSelector(
  [getEntities],
  (entities) => entities?.countries
);

export const getAddressTypeEntities = createSelector(
  [getEntities],
  (entities) => entities?.addressTypes
);

export const getDocumentTypeEntities = createSelector(
  [getEntities],
  (entities) => entities?.documentTypes
);

export const getCommunicationTypeEntities = createSelector(
  [getEntities],
  (entities) => entities?.communicationTypes
);

export const getConsentsEntities = createSelector(
  [getEntities],
  (entities) => entities?.consents
);

export const getPurposeOfStayEntities = createSelector(
  [getEntities],
  (entities) => entities?.purposesOfStay
);

export const getTelephoneRegionPrefixesEntities = createSelector(
  [getEntities],
  (entities) => entities?.telephoneRegionPrefixes
);

export const getNationalitiesEntities = createSelector(
  [getEntities],
  (entities) => entities?.nationalities
);

export const getTitlesEntities = createSelector(
  [getEntities],
  (entities) => entities?.titles
);

export const getProfileSuffixesEntities = createSelector(
  [getEntities],
  (entities) => entities?.profileSuffixes
);

export const getLanguagesEntities = createSelector(
  [getEntities],
  (entities) => entities?.languages
);

export const getDefaultEmailType = createSelector(
  getGeneralSettings,
  getCommunicationTypeEntities,
  (generalSettings, communicationTypes) => {
    const defaultEmailTypeId = generalSettings?.DEFAULT_EMAIL_TYPE_ID;

    const defaultType =
      communicationTypes && defaultEmailTypeId
        ? communicationTypes.find(
            (addressType) => addressType.id === defaultEmailTypeId
          )
        : undefined;

    return defaultType?.isActive ? defaultType : undefined;
  }
);

export const getDefaultAddressType = createSelector(
  getGeneralSettings,
  getAddressTypeEntities,
  (generalSettings, addressTypes) => {
    const defaultAddressTypeId = generalSettings?.DEFAULT_ADDRESS_TYPE_ID;

    const defaultType =
      addressTypes && defaultAddressTypeId
        ? addressTypes.find(
            (addressType) => addressType.id === defaultAddressTypeId
          )
        : undefined;

    return defaultType?.isActive ? defaultType : undefined;
  }
);

export const getDefaultMobileType = createSelector(
  getGeneralSettings,
  getCommunicationTypeEntities,
  (generalSettings, communicationTypes) => {
    const defaultMobileTypeId = generalSettings?.DEFAULT_MOBILE_TYPE_ID;

    const defaultType =
      communicationTypes && defaultMobileTypeId
        ? communicationTypes.find(
            (addressType) => addressType.id === defaultMobileTypeId
          )
        : undefined;

    return defaultType?.isActive ? defaultType : undefined;
  }
);

export const getDefaultPhoneType = createSelector(
  getGeneralSettings,
  getCommunicationTypeEntities,
  (generalSettings, communicationTypes) => {
    const defaultPhoneTypeId = generalSettings?.DEFAULT_PHONE_TYPE_ID;

    const defaultType =
      communicationTypes && defaultPhoneTypeId
        ? communicationTypes.find(
            (addressType) => addressType.id === defaultPhoneTypeId
          )
        : undefined;

    return defaultType?.isActive ? defaultType : undefined;
  }
);

export const getEmailCommunicationTypes = createSelector(
  getCommunicationTypeEntities,
  (communicationTypes) => {
    return communicationTypes?.filter(
      (type) => type.communicationMode?.code === COMMUNICATION_MODES.email
    );
  }
);

export const getMobileCommunicationTypes = createSelector(
  getCommunicationTypeEntities,
  (communicationTypes) => {
    return communicationTypes?.filter(
      (type) => type.communicationMode?.code === COMMUNICATION_MODES.mobile
    );
  }
);

export const getPhoneCommunicationTypes = createSelector(
  getCommunicationTypeEntities,
  (communicationTypes) => {
    return communicationTypes?.filter(
      (type) => type.communicationMode?.code === COMMUNICATION_MODES.phone
    );
  }
);

export const getCommunicationModeOrder = createSelector(
  [getCommunicationTypeEntities],
  (communicationTypes) => {
    if (!communicationTypes) return DEFAULT_CONTACT_MODE_ORDER;
    const sortedCommunicationTypes = sortDisplayElements(communicationTypes);
    const modeOrder = sortedCommunicationTypes
      .map((channel) => channel.communicationMode?.code)
      .filter(
        (item) => item && Object.values(COMMUNICATION_MODES).includes(item)
      )
      .filter(isDefined);

    DEFAULT_CONTACT_MODE_ORDER.forEach((mode) => {
      if (modeOrder.includes(mode)) return;
      modeOrder.push(mode);
    });

    return modeOrder;
  }
);

export const getDateTimeFormats = createSelector(
  getGeneralSettings,
  getPropertyConfiguration,
  (generalSettings, property) => {
    const longDateFormat =
      generalSettings?.LONG_DATE_FORMAT ||
      property?.dateTimeFormat?.longDateFormat;
    const shortDateFormat =
      generalSettings?.SHORT_DATE_FORMAT ||
      property?.dateTimeFormat?.shortDateFormat;

    const adjustedLongDateFormat =
      i18next.language === Languages.zhHans ||
      i18next.language === Languages.zhHant
        ? mapLongDateFormat(CHINESE_HARDCODED_DATE_FORMAT)
        : longDateFormat && mapLongDateFormat(longDateFormat);

    const adjustedShortDateFormat =
      shortDateFormat && mapShortDateFormat(shortDateFormat);

    return {
      shortDateFormat: adjustedShortDateFormat,
      longDateFormat: adjustedLongDateFormat,
      timeFormat: property?.dateTimeFormat?.timeFormat,
    };
  }
);
