import { KioskProfile, RawKioskProfile } from './kioskProfile';
import { KioskReservation, RawKioskReservation } from './kioskReservation';

export interface RawKioskRegCardDetails {
  confirmationNumber?: string;
  id: string;
  profile: RawKioskProfile;
  reservation: RawKioskReservation;
}

export class KioskRegCardDetails {
  public confirmationNumber?: string;
  public id: string;
  public profile: KioskProfile;
  public reservation: KioskReservation;

  constructor(raw: RawKioskRegCardDetails) {
    this.confirmationNumber = raw.confirmationNumber;
    this.id = raw.id;
    this.profile = new KioskProfile(raw.profile);
    this.reservation = new KioskReservation(raw.reservation);
  }
}
