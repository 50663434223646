import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonPattern,
  SelectField,
  SelectFieldType,
  SelectOption,
  Text,
  TextSize,
} from '@ac/kiosk-components';
import {
  createRequiredValidator,
  Form,
  formFieldFactory,
} from '@ac/react-infrastructure';

import { FormValidator, mapFieldRenderProps } from 'utils/form';

import './LoginForm.scss';

export interface LoginFormData {
  property: string;
  device: string;
}

const FormField = formFieldFactory<LoginFormData>();

interface PropertyFormProps {
  initialValues: LoginFormData;
  properties: SelectOption[];
  devices: SelectOption[];
  deviceSelectLoading?: boolean;
  propertySelectLoading?: boolean;
  onSubmit: (data: LoginFormData) => void;
  onSelectProperty: (property: string) => void;
  onCancel: () => void;
}

export const LoginForm = ({
  initialValues,
  properties,
  devices,
  deviceSelectLoading,
  propertySelectLoading,
  onSubmit,
  onSelectProperty,
  onCancel,
}: PropertyFormProps): JSX.Element => {
  const { t } = useTranslation();

  const validator = useRef(
    new FormValidator({
      property: createRequiredValidator('VALIDATION.FIELD_IS_REQUIRED'),
      device: createRequiredValidator('VALIDATION.FIELD_IS_REQUIRED'),
    })
  );

  return (
    <>
      <Text size={TextSize.xlg} className="spacing-lg">
        {t('LOGIN.FORM.TITLE')}
      </Text>

      <Form
        initialValues={initialValues}
        validate={validator.current.validate}
        onSubmit={onSubmit}
      >
        {(formRenderProps): JSX.Element => (
          <>
            <FormField valuePath="property">
              {(fieldRenderProps): JSX.Element => {
                const formFieldProps = mapFieldRenderProps(fieldRenderProps);

                return (
                  <SelectField
                    {...formFieldProps}
                    type={SelectFieldType.inline}
                    placeholder={t('LOGIN.FORM.SELECT_PROPERTY_PLACEHOLDER')}
                    label={t('LOGIN.FORM.PROPERTY')}
                    options={properties}
                    optionsLoading={propertySelectLoading}
                    onChange={(value: string): void => {
                      formFieldProps.onChange(value);
                      formRenderProps.form.change('device', undefined);
                      formRenderProps.form.resetFieldState('device');
                      onSelectProperty(value);
                    }}
                  />
                );
              }}
            </FormField>

            <FormField valuePath="device">
              {(deviceFormField): JSX.Element => (
                <SelectField
                  {...mapFieldRenderProps(deviceFormField)}
                  disabled={propertySelectLoading}
                  optionsLoading={deviceSelectLoading}
                  placeholder={t('LOGIN.FORM.SELECT_DEVICE_PLACEHOLDER')}
                  type={SelectFieldType.inline}
                  label={t('LOGIN.FORM.DEVICE')}
                  options={devices}
                />
              )}
            </FormField>
            <div className="padding-lg login-form-buttons-wrapper">
              <Button onClick={onCancel} pattern={ButtonPattern.secondary}>
                {t('SHARED.CANCEL')}
              </Button>
              <Button
                disabled={
                  formRenderProps.invalid ||
                  deviceSelectLoading ||
                  propertySelectLoading
                }
                onClick={formRenderProps.handleSubmit}
              >
                {t('SHARED.CONFIRM')}
              </Button>
            </div>
          </>
        )}
      </Form>
    </>
  );
};
