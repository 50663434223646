import { ReducerMap } from 'redux-actions';

import { ApiError } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

import { KioskDevice, KioskProperty } from 'api/KioskApi/entries';

import * as actions from './actions';
import { LoginViewState } from './interfaces';

const initialState: LoginViewState = {
  fetching: {
    properties: false,
    devices: false,
  },
  errors: [],
  properties: [],
  devices: [],
};

const reducerMap: ReducerMap<LoginViewState, unknown> = {
  [actions.fetchPropertyList.trigger]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        properties: true,
      },
    };
  },

  [actions.fetchPropertyList.success]: (
    state,
    action: Action<KioskProperty[]>
  ) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        properties: false,
      },
      properties: action.payload,
    };
  },

  [actions.fetchPropertyList.failure]: (
    state,
    action: Action<ApiError | Error>
  ) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        properties: false,
      },
      errors: [...state.errors, action.payload],
    };
  },
  [actions.fetchDeviceList.trigger]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        devices: true,
      },
    };
  },

  [actions.fetchDeviceList.success]: (state, action: Action<KioskDevice[]>) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        devices: false,
      },
      devices: action.payload,
    };
  },

  [actions.fetchDeviceList.failure]: (
    state,
    action: Action<ApiError | Error>
  ) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        devices: false,
      },
      errors: [...state.errors, action.payload],
    };
  },
};

export const loginView = handleActions<LoginViewState>(
  reducerMap,
  initialState
);
