import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import backend from 'i18next-http-backend';
import moment from 'moment';

import { KioskComponentsConfigurator } from '@ac/kiosk-components';
import { setUpI18next } from '@ac/library-utils/dist/i18next-config';

import { DEFAULT_APP_LANGUAGE } from 'configs/constants';

import 'moment/locale/fr';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/pl';
import 'moment/locale/it';
import 'moment/locale/zh-cn';
import 'moment/locale/tr';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/nl';
import 'moment/locale/th';

export const DEFAULT_NAMESPACE = 'translation';
export const LANGUAGE_NAME_KEY = 'LANGUAGE_NAME';

export enum Languages {
  nl = 'nl',
  fr = 'fr',
  de = 'de',
  es = 'es',
  it = 'it',
  en = 'en',
  zh = 'zh',
  zhHans = 'zh-hans',
  zhHant = 'zh-hant',
  ko = 'ko',
  pl = 'pl',
  ja = 'ja',
  tr = 'tr',
  th = 'th',
}

const momentLanguages = {
  [Languages.fr]: 'fr',
  [Languages.ja]: 'ja',
  [Languages.en]: 'en',
  [Languages.ko]: 'ko',
  [Languages.pl]: 'pl',
  [Languages.zhHans]: 'zh-cn',
  [Languages.zhHant]: 'zh-cn',
  [Languages.tr]: 'tr',
  [Languages.de]: 'de',
  [Languages.es]: 'es',
  [Languages.nl]: 'nl',
  [Languages.th]: 'th',
  [Languages.it]: 'it',
};

export const initI18nTranslations = (): void => {
  i18n.on('initialized', (options) => {
    const language = options.lng;
    if (language && language in momentLanguages) {
      moment.locale(momentLanguages[language as keyof typeof momentLanguages]);
    }
  });

  setUpI18next(i18n, {
    plugins: { backend, initReactI18next },
    moduleName: 'registrationCard',
    repositoryName: 'frontend-registration-card',
    useRemoteTranslations: true,
    initOptions: {
      lng: DEFAULT_APP_LANGUAGE,
      interpolation: { escapeValue: false },
    },
  });
};

export const changeLanguage = async (language?: string): Promise<void> => {
  if (!language) return;

  if (language in momentLanguages) {
    moment.locale(momentLanguages[language as keyof typeof momentLanguages]);
  }
  await KioskComponentsConfigurator.changeLanguage(language);
  await i18n.changeLanguage(language);
};
