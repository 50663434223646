import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonPattern,
  ExpandPanel,
  IconTypes,
  Separator,
  Text,
  TextSize,
  TextWeight,
} from '@ac/kiosk-components';

import { ProfilePresentationDataElement } from 'utils/regCardPresentationDataMappers';

import { PersonalSubSection } from '../PersonalSubSection/PersonalSubSection';

interface PersonalNestedSectionProps {
  title: string;
  noDataInfo?: string;
  data?: ProfilePresentationDataElement[];
  className?: string;
  dataTestSelector?: string;
  withSeparators?: boolean;
  enableCollapsible?: boolean;
}

export const PersonalNestedSection = ({
  data = [],
  className,
  title,
  noDataInfo,
  dataTestSelector,
  withSeparators,
  enableCollapsible,
}: PersonalNestedSectionProps): JSX.Element => {
  const { t } = useTranslation();
  const [isSectionExpanded, setSectionExpanded] = useState(false);

  const sectionToggle = useCallback(() => {
    return setSectionExpanded(!isSectionExpanded);
  }, [isSectionExpanded]);

  const subSectionTypes = useMemo(() => {
    return {
      nonCollapsableSections: data.filter((item) => !item.isCollapsible),
      collapsibleSections: data.filter((item) => item.isCollapsible),
    };
  }, [data]);

  return (
    <div data-test-selector={dataTestSelector} className={className}>
      <Text size={TextSize.lg} className="spacing-top-sm">
        {title}
      </Text>
      {data?.length ? (
        <ExpandPanel isExpanded={!enableCollapsible || isSectionExpanded}>
          {subSectionTypes.nonCollapsableSections.map(
            ({ header, sectionData }, index) => (
              <Fragment key={`${index}-${header}`}>
                {withSeparators && index > 0 && (
                  <Separator className="spacing-top-xlg spacing-bottom-xlg" />
                )}
                <PersonalSubSection
                  title={header}
                  data={sectionData}
                  className="spacing-top-sm"
                />
              </Fragment>
            )
          )}

          {!!subSectionTypes?.collapsibleSections.length && (
            <>
              {enableCollapsible && (
                <Button
                  className="padding-sm spacing-top-sm"
                  pattern={ButtonPattern.tertiary}
                  icon={
                    isSectionExpanded ? IconTypes.collapse : IconTypes.expand
                  }
                  onClick={sectionToggle}
                >
                  {isSectionExpanded
                    ? t('COMPONENTS.PERSONAL_DETAILS_SECTION.BUTTONS.SHOW_LESS')
                    : t(
                        'COMPONENTS.PERSONAL_DETAILS_SECTION.BUTTONS.SHOW_MORE'
                      )}
                </Button>
              )}

              {subSectionTypes.collapsibleSections.map(
                ({ header, sectionData }, index) => (
                  <ExpandPanel.ExtendableElement key={`${header}-${index}`}>
                    {withSeparators && (
                      <Separator className="spacing-top-xlg spacing-bottom-xlg" />
                    )}
                    <PersonalSubSection
                      title={header}
                      data={sectionData}
                      className="spacing-top-sm"
                    />
                  </ExpandPanel.ExtendableElement>
                )
              )}
            </>
          )}
        </ExpandPanel>
      ) : (
        <Text weight={TextWeight.light} className="spacing-top-sm">
          {noDataInfo}
        </Text>
      )}
    </div>
  );
};
