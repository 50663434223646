import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Button,
  ButtonPattern,
  Section,
  SectionType,
  Text,
  TextGroup,
  TextSize,
} from '@ac/kiosk-components';

import {
  getDateTimeFormats,
  getGeneralSettings,
  getReservationHeaderDefinition,
} from 'store/settings/selectors';
import { mapComplementaryData } from 'utils/regCardPresentationDataMappers';

import { BaseObject } from 'types/shared';

interface ComplementaryDetailsSectionProps {
  complementaryDetails?: BaseObject;
  editButtonVisible?: boolean;
  onEditButtonClick?: () => void;
}

export const ComplementaryDetailsSection = ({
  complementaryDetails,
  editButtonVisible,
  onEditButtonClick,
}: ComplementaryDetailsSectionProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const generalSettings = useSelector(getGeneralSettings);
  const dateTimeFormat = useSelector(getDateTimeFormats);
  const reservationHeaderDefinition = useSelector(
    getReservationHeaderDefinition
  );

  const displayDetails = useMemo(() => {
    return mapComplementaryData(
      complementaryDetails,
      reservationHeaderDefinition,
      {
        shortDateFormat: dateTimeFormat?.shortDateFormat,
        timeFormat: dateTimeFormat?.timeFormat,
        enabledCustomFields: generalSettings?.RESERVATION_HEADER_CUSTOM_FIELDS,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateTimeFormat?.shortDateFormat,
    dateTimeFormat?.timeFormat,
    complementaryDetails,
    reservationHeaderDefinition,
    i18n.language,
  ]);

  return (
    <Section type={SectionType.wide}>
      <Text size={TextSize.lg}>{t('SHARED.COMPLEMENTARY_DETAILS')}</Text>
      <div className="spacing-top-sm spacing-bottom-sm">
        {displayDetails.map(({ label, value }, index) => (
          <TextGroup
            className="spacing-top-sm"
            key={`${label}-${index}`}
            label={label}
            value={value}
          />
        ))}
      </div>
      {editButtonVisible && (
        <Button
          dataTestSelector="edit-personal-details-button"
          className="spacing-top-xlg spacing-right-none spacing-left-auto"
          pattern={ButtonPattern.secondary}
          onClick={onEditButtonClick}
        >
          {t('SHARED.EDIT')}
        </Button>
      )}
    </Section>
  );
};
