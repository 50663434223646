import { Action, handleActions, ReducerMap } from 'redux-actions';

import {
  BaseKioskConfigurationEntity,
  KioskStateDetails,
} from 'api/KioskApi/entries';

import {
  DictionaryStatuses,
  SuccessAction,
} from './interfaces/dictionaryDataStatus';
import * as actions from './actions';
import { AdditionalDictionariesState } from './interfaces';

const initialState: AdditionalDictionariesState = {
  countryStates: undefined,
  countryDistricts: undefined,
};

const reducerMap: ReducerMap<AdditionalDictionariesState, unknown> = {
  [actions.fetchCountryStates.trigger]: (state, action: Action<string>) => {
    const key = action.payload;

    return {
      ...state,
      countryStates: {
        ...state.countryStates,
        [key]: {
          ...state.countryStates?.[key],
          status: DictionaryStatuses.fetching,
        },
      },
    };
  },

  [actions.fetchCountryStates.success]: (
    state,
    action: Action<SuccessAction<KioskStateDetails[]>>
  ) => {
    const { key, data } = action.payload;

    return {
      ...state,
      countryStates: {
        ...state.countryStates,
        [key]: {
          ...state.countryStates?.[key],
          status: DictionaryStatuses.completed,
          data,
        },
      },
    };
  },

  [actions.fetchCountryStates.failure]: (state, action: Action<string>) => {
    const key = action.payload;

    return {
      ...state,
      countryStates: {
        ...state.countryStates,
        [key]: {
          ...state.countryStates?.[key],
          status: DictionaryStatuses.failed,
        },
      },
    };
  },

  [actions.fetchCountryDistricts.trigger]: (state, action: Action<string>) => {
    const key = action.payload;

    return {
      ...state,
      countryDistricts: {
        ...state.countryDistricts,
        [key]: {
          ...state.countryDistricts?.[key],
          status: DictionaryStatuses.fetching,
        },
      },
    };
  },

  [actions.fetchCountryDistricts.success]: (
    state,
    action: Action<SuccessAction<BaseKioskConfigurationEntity[]>>
  ) => {
    const { key, data } = action.payload;

    return {
      ...state,
      countryDistricts: {
        ...state.countryDistricts,
        [key]: {
          ...state.countryDistricts?.[key],
          status: DictionaryStatuses.completed,
          data,
        },
      },
    };
  },

  [actions.fetchCountryDistricts.failure]: (state, action: Action<string>) => {
    const key = action.payload;

    return {
      ...state,
      countryDistricts: {
        ...state.countryDistricts,
        [key]: {
          ...state.countryDistricts?.[key],
          status: DictionaryStatuses.failed,
        },
      },
    };
  },
};

export const additionalDictionaries = handleActions<AdditionalDictionariesState>(
  reducerMap,
  initialState
);
