import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import { paths } from 'configs';
import {
  requirePropertyAndDevice,
  requireRegCardProcessInProgress,
  requireSettingsInitialized,
  withIdleCheck,
} from 'utils/hoc';
import { Consent } from 'views/Consent/Consent';
import { ExternalLink } from 'views/ExternalLink/ExternalLink';
import { Finish } from 'views/Finish/Finish';
import { Login } from 'views/Login/Login';
import { RegistrationCardConfirmation } from 'views/RegistrationCardConfirmation/RegistrationCardConfirmation';
import { RegistrationCardEditComplementary } from 'views/RegistrationCardEditComplementary/RegistrationCardEditComplementary';
import { RegistrationCardEditPersonal } from 'views/RegistrationCardEditPersonal/RegistrationCardEditPersonal';
import { RegistrationCardSummary } from 'views/RegistrationCardSummary/RegistrationCardSummary';
import { SameDeviceEnvSetup } from 'views/SameDeviceEnvSetup/SameDeviceEnvSetup';
import { Setup } from 'views/Setup/Setup';
import { Welcome } from 'views/Welcome/Welcome';

const processRoutes: RouteObject[] = [
  {
    path: paths.REGISTRATION_CARD_SUMMARY,
    element: React.createElement(
      requireRegCardProcessInProgress(withIdleCheck(RegistrationCardSummary))
    ),
  },
  {
    path: paths.REGISTRATION_CARD_EDIT_PERSONAL,
    element: React.createElement(
      requireRegCardProcessInProgress(
        withIdleCheck(RegistrationCardEditPersonal)
      )
    ),
  },
  {
    path: paths.REGISTRATION_CARD_EDIT_COMPLEMENTARY_DETAILS,
    element: React.createElement(
      requireRegCardProcessInProgress(
        withIdleCheck(RegistrationCardEditComplementary)
      )
    ),
  },
  {
    path: paths.REGISTRATION_CARD_CONFIRMATION,
    element: React.createElement(
      requireRegCardProcessInProgress(
        withIdleCheck(RegistrationCardConfirmation)
      )
    ),
  },
  {
    path: paths.FINISH,
    element: React.createElement(requireRegCardProcessInProgress(Finish)),
  },
  {
    path: paths.EXTERNAL_LINK,
    element: React.createElement(
      requireRegCardProcessInProgress(withIdleCheck(ExternalLink))
    ),
  },
  {
    path: paths.CONSENT,
    element: React.createElement(
      requireRegCardProcessInProgress(withIdleCheck(Consent))
    ),
  },
];

const mainRoutes: RouteObject[] = [
  {
    path: paths.LOGIN,
    element: React.createElement(Login),
  },
  {
    path: paths.SETUP,
    element: React.createElement(requirePropertyAndDevice(Setup)),
  },
  {
    path: paths.SAME_DEVICE_ENTRY_PATH,
    children: [
      {
        index: true,
        element: React.createElement(SameDeviceEnvSetup),
      },
      ...processRoutes,
    ],
  },
  {
    path: paths.EXTERNAL_DEVICE_ENTRY_PATH,
    children: [
      {
        index: true,
        element: React.createElement(
          requirePropertyAndDevice(requireSettingsInitialized(Welcome))
        ),
      },
      ...processRoutes,
    ],
  },
  {
    path: '*',
    element: <Navigate to={paths.LOGIN} replace />,
  },
];

export const AppRoutes = (): JSX.Element | null => {
  return useRoutes(mainRoutes);
};
