import {
  CodePathParam,
  getFactory,
  getFileFactory,
  IdPathParam,
  listFactory,
} from '@ac/library-api';

import { ImageSizeQueryParams } from './entries/shared/queryParams';
import {
  BaseKioskConfigurationEntity,
  KioskBusinessDate,
  KioskConfiguration,
  KioskDevice,
  KioskLocalizedTitlesEntryDetails,
  KioskProperty,
  KioskRegistrationCard,
  KioskStateDetails,
  RawBaseKioskConfigurationEntity,
  RawKioskBusinessDate,
  RawKioskConfiguration,
  RawKioskDevice,
  RawKioskLocalizedTitlesEntryDetails,
  RawKioskProperty,
  RawKioskRegistrationCard,
  RawKioskStateDetails,
} from './entries';

const path = {
  properties: '/kiosk/v0/electronic-registration/initialization/properties',
  devices: '/kiosk/v0/electronic-registration/devices',
  configuration: '/kiosk/v0/electronic-registration/configuration',
  states:
    '/kiosk/v0/electronic-registration/configuration/countries/{code}/states',
  districts:
    '/kiosk/v0/electronic-registration/configuration/countries/{code}/districts',
  localizedTitles: '/kiosk/v0/electronic-registration/configuration/titles',
  effectiveValuesContentImage:
    '/kiosk/v0/electronic-registration/configuration/settings/{code}/content/image',
  registrationCard:
    '/kiosk/v0/electronic-registration/devices/{id}/registration-card',
  businessDate: '/kiosk/v0/electronic-registration/business-date',
};

export const ApiCacheKeyProperties = 'KioskApiProperties';
export const ApiCacheKeyDevices = 'KioskApiDevices';
export const ApiCacheKeyConfiguration = 'KioskApiConfiguration';
export const ApiCacheKeyStates = 'KioskApiStates';
export const ApiCacheKeyDistricts = 'KioskApiDistricts';
export const ApiCacheKeyLocalizedTitles = 'KioskApiLocalizedTitles';
export const ApiCacheKeyRegistrationCard = 'KioskApiRegistrationCard';
export const ApiCacheKeyBusinessDate = 'KioskApiBusinessDate';

export const getProperties = listFactory<
  undefined,
  RawKioskProperty,
  KioskProperty
>({
  path: path.properties,
  cacheKey: ApiCacheKeyProperties,
  ctor: KioskProperty,
});

export const getDevices = listFactory<undefined, RawKioskDevice, KioskDevice>({
  path: path.devices,
  cacheKey: ApiCacheKeyDevices,
  ctor: KioskDevice,
});

export const getEffectiveValuesContentImage = getFileFactory<
  CodePathParam,
  ImageSizeQueryParams
>({
  path: path.effectiveValuesContentImage,
});

export const getStates = listFactory<
  CodePathParam,
  RawKioskStateDetails,
  KioskStateDetails
>({
  path: path.states,
  cacheKey: ApiCacheKeyStates,
  ctor: KioskStateDetails,
});

export const getDistricts = listFactory<
  CodePathParam,
  RawBaseKioskConfigurationEntity,
  BaseKioskConfigurationEntity
>({
  path: path.districts,
  cacheKey: ApiCacheKeyDistricts,
  ctor: BaseKioskConfigurationEntity,
});

export const getLocalizedTitles = listFactory<
  undefined,
  RawKioskLocalizedTitlesEntryDetails,
  KioskLocalizedTitlesEntryDetails
>({
  path: path.localizedTitles,
  cacheKey: ApiCacheKeyLocalizedTitles,
  ctor: KioskLocalizedTitlesEntryDetails,
});

export const getConfiguration = getFactory<
  undefined,
  RawKioskConfiguration,
  KioskConfiguration
>({
  path: path.configuration,
  cacheKey: ApiCacheKeyConfiguration,
  ctor: KioskConfiguration,
});

export const getRegistrationCard = getFactory<
  IdPathParam,
  RawKioskRegistrationCard,
  KioskRegistrationCard
>({
  path: path.registrationCard,
  cacheKey: ApiCacheKeyRegistrationCard,
  ctor: KioskRegistrationCard,
});

export const getBusinessDate = getFactory<
  undefined,
  RawKioskBusinessDate,
  KioskBusinessDate
>({
  path: path.businessDate,
  cacheKey: ApiCacheKeyBusinessDate,
  ctor: KioskBusinessDate,
});
