import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Text, TextSize, TextWeight } from '@ac/kiosk-components';

import { BaseKioskConfigurationEntity } from 'api/KioskApi/entries';
import { mapPurposeOfStay } from 'utils/regCardPresentationDataMappers';

interface PurposeOfStayProps {
  dataTestSelector?: string;
  className?: string;
  purposeOfStay?: BaseKioskConfigurationEntity;
}

export const PurposeOfStay = ({
  dataTestSelector,
  className,
  purposeOfStay,
}: PurposeOfStayProps): JSX.Element => {
  const [t] = useTranslation();

  const purposeOfStayDescription = useMemo(() => {
    return mapPurposeOfStay(purposeOfStay);
  }, [purposeOfStay]);

  return (
    <div data-test-selector={dataTestSelector} className={className}>
      <Text size={TextSize.xlg} className="spacing-bottom-sm">
        {t('REGISTRATION_CARD.ADDITIONAL_DETAILS.TITLE')}
      </Text>
      <Text size={TextSize.xs} className="spacing-top-xlg">
        {t('REGISTRATION_CARD.ADDITIONAL_DETAILS.HINT')}
      </Text>
      <Text
        size={TextSize.lg}
        weight={TextWeight.light}
        className="spacing-top-sm"
      >
        {purposeOfStayDescription}
      </Text>
    </div>
  );
};
