import {
  FieldRenderProps,
  RadioButtonFieldRenderProps,
  SingleCheckboxFieldRenderProps,
  ValidationResult,
} from '@ac/react-infrastructure';

import { translateValidationResult } from './translateValidationResult';

export interface MappedFormElementRenderProps<Type = unknown> {
  name: string;
  checked?: boolean;
  onChange: (data?: React.ChangeEvent | Type) => void;
  onFocus: () => void;
  onBlur: () => void;
  value?: Type;
  validationStatus?: ValidationResult;
}

export const mapFieldRenderProps = ({
  input: {
    name,
    validationStatus,
    value,
    onFocusIn,
    onFocusOut,
    onChangeCallback,
  },
}: FieldRenderProps<string | undefined>): MappedFormElementRenderProps<
  string | undefined
> => {
  return {
    name,
    onChange: onChangeCallback,
    onFocus: onFocusIn,
    onBlur: onFocusOut,
    value,
    validationStatus: translateValidationResult(validationStatus),
  };
};

export const mapRadioFieldRenderProps = ({
  input: {
    name,
    validationStatus,
    checked,
    value,
    onChange,
    onFocusIn,
    onFocusOut,
  },
}: RadioButtonFieldRenderProps<
  string | number | undefined
>): MappedFormElementRenderProps<string | number | undefined> => {
  return {
    name,
    checked,
    onChange,
    onFocus: onFocusIn,
    onBlur: onFocusOut,
    value,
    validationStatus: translateValidationResult(validationStatus),
  };
};

export const mapCheckboxFieldRenderProps = ({
  input: { name, checked, validationStatus, onChange, onFocusIn, onFocusOut },
}: SingleCheckboxFieldRenderProps): MappedFormElementRenderProps<
  string | undefined
> => {
  return {
    name,
    checked,
    onChange: (e: React.ChangeEvent<HTMLInputElement>): void =>
      onChange(e.target.checked),
    onFocus: onFocusIn,
    onBlur: onFocusOut,
    validationStatus: translateValidationResult(validationStatus),
  };
};
