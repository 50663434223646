import {
  createAction,
  getOneAction,
  updateAction,
} from '@ac/library-utils/dist/redux-utils';

import { KioskRegCardDetails } from 'api/KioskApi/entries';

import { UpdateConsentsAndStayPayload } from './interfaces';

export const receivedRegistrationCardForDevice = createAction<string>(
  '@electronicRegistrationProcess/receivedRegistrationCardForDevice'
);

export const fetchRegistrationCardDetails = getOneAction<
  string,
  KioskRegCardDetails
>('@electronicRegistrationProcess/fetchRegistrationCardDetails');

export const clearElectronicRegistrationProcess = createAction(
  '@electronicRegistrationProcess/clearElectronicRegistrationProcess'
);

export const cancelElectronicRegistrationProcess = updateAction(
  '@electronicRegistrationProcess/cancelElectronicRegistrationProcess'
);

export const startElectronicRegistrationProcess = updateAction<
  undefined,
  string
>('@electronicRegistrationProcess/startElectronicRegistrationProcess');

export const cacheCheckedConsents = createAction<{ [key: string]: boolean }>(
  '@electronicRegistrationProcess/cacheCheckedConsents'
);

export const cachePurposeOfStay = createAction<string>(
  '@electronicRegistrationProcess/cachePurposeOfStay'
);

export const cacheEtd = createAction<string>(
  '@electronicRegistrationProcess/cacheEtd'
);

export const updateConsentsAndPurposeOfStay = updateAction<
  UpdateConsentsAndStayPayload,
  undefined
>('@electronicRegistrationProcess/updateConsentsAndPurposeOfStay');

export const uploadSignedCard = updateAction<Blob, undefined>(
  '@electronicRegistrationProcess/uploadSignedCard'
);

export const completeRegistrationCard = createAction(
  '@electronicRegistrationProcess/completeRegistrationCard'
);

export const anotherSessionOpenedInNewTab = createAction(
  '@electronicRegistrationProcess/anotherSessionOpenedInNewTab'
);
